@import "./assets/scss/app.scss";
@import "../node_modules/react-redux-toastr/src/styles/index";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.date-is-invalid input {
  border-color: #ff586b;
}

.error {
  color: #ff586b !important;
}

td .rdt.rdtOpen .rdtPicker {
  width: 310px;
  position: relative;
}
