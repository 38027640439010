.wrapper {
   position: relative;
   top: 0;
   height: 100vh;   

   .content-header {
      font-weight: 400;
      font-size: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: 0.25rem;
      font-family: $font-family-sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.05rem;
   }
   .content-sub-header {
      color: darken($body-bg, 60%);
      margin-bottom: 1rem; 
   }

   .collapsed {
      &.app-sidebar {
         width: 60px;
         &:hover {
            width: 250px;
            .nav li.has-sub > a:after {
               display: inherit;
            }
            .menu-item-text,
            .badge {
               display: inline-block;
            }
            .logo .text {
               display: inline-block;
            }
            .nav-toggle {
               display: block !important;
            }
         }
         // .menu-item-text {
         //    visibility: hidden;
         // }
         .menu-item-text {
            visibility: hidden;
         }
         .badge {
            visibility: hidden;
         }
         .logo .text {
            visibility: hidden;
         }
         .item-arrow {
            visibility: hidden;
         }
         .nav-toggle {
            visibility: hidden !important;
         }
         .nav-close {
            visibility: hidden !important;
         }
      }

      .navbar {         
         .navbar-header {
            padding-left: 50px;
         }
      }
      .main-panel {
         .main-content {
            padding-left: 60px;
         }
      }
      footer {
         padding-left: 60px;
      }
   }

   &.menu-collapsed {
      .app-sidebar {
         .navigation li.has-sub > a:after {
            display: none;
         }
      }
   }
}

.bootstrap-navbar {
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   width: 260px;
   display: block;
   z-index: 1;
   color: #fff;
   font-weight: 200;
   background-size: cover;
   background-position: center center;
}
.app-sidebar,
.off-canvas-sidebar {
   font-family: $font-family-base;
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   z-index: 99;
   width: 250px;
   height: 100vh;
   @include sidebar-shadow();
   -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
   transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
   transform: translate3d(0, 0, 0);
   white-space: nowrap;
   visibility: visible;

   &.hide-sidebar {
      transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transform: translate3d(-100%, 0, 0);
   }

   .sidebar-content {
      position: relative;
      height: calc(100vh - 90px);
      overflow: auto;
      z-index: 4;
   }
   .logo-img {
      width: 30px;
      display: inline-block;
      max-height: 30px;
      margin-right: 1rem;
   }

   .logo-tim {
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img {
         width: 60px;
         height: 60px;
      }
   }

   .navigation {
      margin-bottom: 0;
      padding-left: 0;
      list-style: none;

      li {
         position: relative;
         display: block;

         &.has-sub {
            > a > .item-arrow {
               svg {
                  transform: rotate(0);
                  transition: -webkit-transform 0.2s ease-in-out;
                  position: absolute;
                  right: 15px;
                  top: 18px;
               }
            }
            > a {
               margin-bottom: 0;
            }
         }
         &.open {
            > a > .item-arrow svg {
               -webkit-transform: rotate(90deg);
               -moz-transform: rotate(90deg);
               -ms-transform: rotate(90deg);
               -o-transform: rotate(90deg);
               transform: rotate(90deg);
            }
            > a {
               background: rgba(200, 200, 200, 0.2);
            }
         }

         > a {
            display: block;
            overflow: hidden;
            line-height: $font-size-base * 2;
            padding: 10px 30px 10px 14px;
            margin: 5px 0;
            background-color: transparent;
            color: $black;
            transition: all 300ms linear;

            &:before {
               content: "";
               display: block;
               z-index: -1;
               position: absolute;
               left: -100%;
               top: 0;
               width: 100%;
               height: 100%;
               border-right: solid 5px theme-color("danger");
               background: rgba(200, 200, 200, 0.2);
               transition: 0.35s ease left;
            }
         }

         &:not(.open) {
            > ul {
               display: none;
            }
         }

         &:hover > a:hover {
            background: rgba(200, 200, 200, 0.2);
            // color: $black;
         }

         //  &.active > a {
         > a.active {
            &:before {
               left: 0;
            }
         }

         ul {
            padding: 0;
            margin: 0;
            overflow: hidden;
            font-size: 1rem;

            li {
               a {
                  display: block;
                  padding: 8px 18px 8px 60px;
               }
               ul {
                  li {
                     a {
                        padding: 8px 18px 8px 70px;
                     }
                  }
               }
            }
         }
      }

      .menu-item-text {
         margin: 0;
         font-size: 0.95rem;
         font-weight: 400;
         letter-spacing: 0.6px;
         opacity: 1;
         height: auto;
         -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
         transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      .badge.badge-pill {
         position: absolute;
         top: 16px;
         right: 32px;
         padding: 4px 8px;
         font-weight: 400;         
         -webkit-transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
         transition: width 0.3s cubic-bezier(0, 0, 0.2, 1);
      }

      i {
         font-size: 18px;
         float: left;
         margin-right: 10px;
         line-height: 2rem;
         width: 30px;
         text-align: center;
         color: #a9afbb;
      }
   }

   .sidebar-background {
      position: absolute;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: block;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center center;

      &:after {
         position: absolute;
         z-index: 3;
         width: 100%;
         height: 100%;
         content: "";
         display: block;
         background: #ffffff;
         opacity: 0.93;
      }
   }

   .logo {
      position: relative;
      padding: 20px 10px;
      z-index: 4;

      p {
         float: left;
         font-size: 20px;
         margin: 10px 10px;
         color: $white;
         line-height: 20px;
         font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }

      .logo-text {
         text-transform: uppercase;
         padding: 5px 0 5px 5px;
         display: block;
         font-size: 1.6rem;
         letter-spacing: 2px;
         color: $black;
         text-align: left;
         font-weight: 400;
      }
   }

   .logo-tim {
      border-radius: 50%;
      border: 1px solid #333;
      display: block;
      height: 61px;
      width: 61px;
      float: left;
      overflow: hidden;

      img {
         width: 60px;
         height: 60px;
      }
   }

   &:after,
   &:before {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
   }

   &:before {
      opacity: 0.33;
   }

   &:after {
      z-index: 3;
      opacity: 1;
   }

   &[data-image]:after,
   &.has-image:after {
      opacity: 0.77;
   }

   // Background colors
   &[data-background-color="white"] {
      @include set-background-color($white);
   }
   &[data-background-color="black"] {
      @include set-background-color(#282a3c);
   }
   &[data-background-color="blue"] {
      @include set-background-color(#5a82f6);
   }
   &[data-background-color="red"] {
      @include set-background-color(#d34048);
   }   
   &[data-background-color="purple"] {
      @include set-background-color(#7367f0);
   }
   &[data-background-color="orange"] {
      @include set-background-color(#E64A19);
   }
   &[data-background-color="navy-blue"] {
      @include set-background-color(#0e5cad);
   }

   // Gradient Background Colors
   &[data-background-color="pomegranate"] {
      @include set-gradient-background-color(#9b3cb7, #ff396f);
   }
   &[data-background-color="king-yna"] {
      @include set-gradient-background-color(#1a2a6c, #b21f1f);
   }
   &[data-background-color="ibiza-sunset"] {
      @include set-gradient-background-color(#ee0979, #ff6a00);
   }
   &[data-background-color="flickr"] {
      @include set-gradient-background-color(#33001b, #ff0084);
   }
   &[data-background-color="purple-bliss"] {
      @include set-gradient-background-color(#360033, #0b8793);
   }
   &[data-background-color="man-of-steel"] {
      @include set-gradient-background-color(#780206, #061161);
   }
   &[data-background-color="purple-love"] {
      @include set-gradient-background-color(#cc2b5e, #753a88);
   }

   // Menu anchor colors
   &[data-background-color="white"] {
      @include set-anchor-color($black);
   }
   &[data-background-color="black"] {
      @include set-anchor-color($white);
   }
   &[data-background-color="red"] {
      @include set-anchor-color($white);
   }

   // Logo Text Color
   &[data-background-color="white"] {
      //@include set-logo-text-color($black);
      @include set-logo-text-color($blueMiddle);
   }
   &[data-color="blue"] {
      @include set-background-color-button(theme-color("info"));
   }
   &[data-color="green"] {
      @include set-background-color-button(theme-color("success"));
   }
   &[data-color="orange"] {
      @include set-background-color-button(theme-color("warning"));
   }
   &[data-color="red"] {
      @include set-background-color-button(theme-color("danger"));
   }
   &[data-color="purple"] {
      @include set-background-color-button(theme-color("primary"));
   }
}

@include media-breakpoint-down(sm) {
   .app-sidebar,
   .off-canvas-sidebar {
      box-shadow: none;
   }
}

.sidebar-header {
   .nav-toggle {
      display: inline-block;
      position: absolute;
      cursor: pointer;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      right: 17px;
      top: 32px;
      color: rgba(255, 255, 255, 1);
   }
   .nav-close {
      display: inline-block;
      position: absolute;
      cursor: pointer;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      right: 14px;
      top: 32px;
      color: white;
   }
}

.off-canvas-sidebar {
   .navigation {
      > li > a,
      > li > a:hover {
         color: $white;
      }

      > li > a:focus {
         background: rgba(200, 200, 200, 0.2);
      }
   }
}

.main-panel {
   position: relative;
   z-index: 2;
   float: right;
   // overflow: auto;
   min-height: 100%;
   @include transform-translate-x(0px);
   @include transition(0.33s, cubic-bezier(0.685, 0.0473, 0.346, 1));

   .main-content {
      position: relative;
      min-height: calc(100% - 123px);
      -webkit-transition: all 0.3s ease;
      transition: all 0.3s ease;
   }
   div {
      .footer {
         border-top: 1px solid #e7e7e7;
      }
   }
   > .navbar {
      margin-bottom: 0;
   }
}
.bootstrap-navbar .navigation {
   margin-top: 20px;
   float: none;
}
.main-panel {
   max-height: 100%;
   height: 100%;
   width: 100%;
}
.sidebar-sm {
   .app-sidebar {
      width: 220px;
   }
}

.sidebar-lg {
   .app-sidebar {
      width: 280px;
   }
}

.wrapper[dir="rtl"] {
   &.collapsed {
      .navbar {
         .navbar-header {
            padding-right: 50px;
            padding-left: auto;
         }
      }
      .main-panel {
         .main-content {
            padding-right: 60px;
            padding-left: auto;
         }
      }
      footer {
         padding-right: 60px;
         padding-left: auto;
      }
   }
}

[dir="rtl"] {
   .content-header {
      text-align: right;
   }
   .content-sub-header {
      text-align: right;
   }

   .wrapper {
      &.collapsed {
         .navbar {
            .navbar-header {
               padding-right: 50px;
               padding-left: auto;
            }
         }
         .main-panel {
            .main-content {
               padding-right: 60px;
               padding-left: auto;
            }
         }
         footer {
            padding-right: 60px;
            padding-left: auto;
         }
      }
   }

   .bootstrap-navbar {
      right: 0;
      left: auto;
   }
   .app-sidebar,
   .off-canvas-sidebar {
      right: 0;
      left: auto;
      //transform: translate3d(0,0,0);

      &.hide-sidebar {
         transform: translate3d(100%, 0, 0);
      }

      .logo-img {
         margin-left: 15px;
         margin-right: auto;
      }

      .logo-tim {
         float: right;
      }

      .navigation {
         padding-right: 0;
         padding-left: auto;

         li {
            &.has-sub {
               > a:after {
                  left: 15px;
                  right: auto;
               }
            }

            > a {
               padding: 10px 14px 10px 30px;
               margin: 5px 0;
               text-align: right;

               &:before {
                  right: -100%;
                  left: auto;
                  border-left: solid 5px theme-color("danger");
                  border-right: 0px;
               }
            }

            &.active > a {
               &:before {
                  right: 0;
                  left: auto;
               }
            }

            ul {
               li {
                  a {
                     padding: 8px 60px 8px 18px;
                  }
                  ul {
                     li {
                        a {
                           padding: 8px 70px 8px 18px;
                        }
                     }
                  }
               }
            }
         }

         .badge.badge-pill {
            left: 28px;
            right: auto;
         }

         i {
            float: right;
            margin-left: 10px;
            margin-right: auto;
         }
      }

      .sidebar-background {
         right: 0;
         left: auto;
      }

      .logo {
         p {
            float: right;
         }

         .logo-text {
            text-align: right;
         }
      }

      .logo-tim {
         float: right;
      }

      &:after,
      &:before {
         right: 0;
         left: auto;
      }
   }

   .sidebar-header {
      .nav-toggle {
         left: 14px;
         right: auto;
      }
      .nav-close {
         left: 14px;
         right: auto;
      }
   }

   .main-panel {
      float: left;
   }
}
